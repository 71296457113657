import _ from 'lodash'
import { gql } from '~/apollo/gql'
import { defineStore } from 'pinia'
import { useDebounceFn } from '@vueuse/core'

export const useSelectionCommittee = defineStore('selectionCommittee', () => {
  const openFeature = useOpenFeature()

  // Film Groups
  const { result, refetch } = useQuery(SelectionFilmGroups, () => ({
    ffid: openFeature.getNumber('current_film_festival'),
    poster: openFeature.getObject('asset_type_ids').poster,
  }), { clientId: 'selection' })
  const filmGroups = computed(() => result.value?.filmGroups || [])
  const awards = computed(() => result.value?.awards || [])
  const filmGroup = computed(() => {
    const route = useRoute()
    if (!route.params.filmGroupId) return null

    return filmGroups.value.find(
      (fg) => fg.id.toString() == route.params.filmGroupId
    )
  })

  // Progress
  const progress = computed(() => filmGroup.value?.selection_progresses[0])
  const { mutate: updateProgress } = useMutation(UpsertSelectionProgress, { clientId: 'selection', })
  const teamIndex = computed({
    get: () => progress.value?.index || 0,
    set: async (index: number) => {
      if (!filmGroup.value) return
      if (index === undefined) return
      return await updateProgress({
        film_group_id: filmGroup.value.id,
        index,
      })
    }
  })
  const currentTeam = computed(() => {
    return filmGroup.value?.teams[teamIndex.value].team
  })

  // Metrics
  const teamMetricEnabled = computed(() => (teamId: number, awardId: number) => {
    const award = _.find(awards.value, { id: awardId })
    if (award?.max_nominations! < 0) return true

    const team = _.find(filmGroup.value?.teams, { team: { id: teamId } })
    const nominations = _.find(team?.team.award_nominations, { award: { id: awardId } })
    return !!nominations
  })
  const getTeamMetric = computed(() => (teamId: number, awardId: number) => {
    const team = _.find(filmGroup.value?.teams, { team: { id: teamId } })
    const metric = _.find(team?.team.selection_metrics, { award_id: awardId })
    return metric ? metric.value : 0
  })
  const { mutate: updateMetric } = useMutation(UpsertSelectionMetric, {
    clientId: 'selection',
    update: (cache, { data: result }) => {
      if (!result) return

      let data = cache.readQuery({
        query: SelectionFilmGroups, variables: {
          ffid: openFeature.getNumber('current_film_festival'),
          poster: openFeature.getObject('asset_type_ids').poster,
        }
      })
      data = {
        ...data!,
        filmGroups: data?.filmGroups.map((fg) => {
          return {
            ...fg,
            teams: fg.teams.map((t) => {
              if (t.team.id != result.insert_selection_metrics_one?.team_id) return t
              if (_.find(t.team.selection_metrics, { award_id: result.insert_selection_metrics_one?.award_id })) return t

              return {
                ...t,
                team: {
                  ...t.team,
                  selection_metrics: [
                    ...t.team.selection_metrics,
                    result.insert_selection_metrics_one!
                  ]
                }
              }
            })
          }
        })!
      }

      cache.writeQuery({ query: SelectionFilmGroups, data })
    },
  })
  const setTeamMetric = computed(() => async (team_id: number, award_id: number, value: number) => {
    return await updateMetric({ team_id, award_id, value })
  })

  // Metric Progress
  const metricProgress = computed(() => {
    return _.chain(filmGroups.value)
      .keyBy('id')
      .mapValues((fg) => {
        return _.meanBy(fg.teams, (t) => {
          const na = _.uniqBy(t.team.award_nominations, n => n.award?.id).length + 1
          return (t.team.selection_metrics_aggregate.aggregate?.count || 0) / na
        })
      })
      .value()
  })
  const metricFilmProgress = computed(() => (filmGroupId: number, teamId: number) => {
    const filmGroup = _.find(filmGroups.value, { id: filmGroupId })
    const team = _.find(filmGroup?.teams, { team: { id: teamId } })
    const na = _.uniqBy(team?.team.award_nominations, n => n.award?.id).length + 1
    return (team?.team.selection_metrics_aggregate.aggregate?.count || 0) / na
  })

  return {
    filmGroups,
    awards,
    filmGroup,
    teamIndex,
    currentTeam,
    teamMetricEnabled,
    getTeamMetric,
    setTeamMetric,
    metricProgress,
    metricFilmProgress,
    refetch
  }
})

const SelectionFilmGroups = gql(`query SelectionFilmGroups($ffid: numeric!, $poster: numeric!) {
  filmGroups: film_groups(where: { film_festival_id: { _eq: $ffid }, type: { _eq: "selection" } }) {
    id
    name
    teams(order_by: { sort: asc }) {
      id
      team {
        id
        name
        team_number
        film_name
        genre
        description
        poster: assets(
          where: { asset_type_id: { _eq: $poster } }
          order_by: { created: desc }
          limit: 1
        ) {
          id
          file_name
        }
        film {
          id
          mux_playback_id
          mux_thumbnail_time
        }
        studio {
          id
          name
        }
        selection_metrics_aggregate(where: {value: {_gt: 0}}) {
          aggregate {
            count
          }
        }
        selection_metrics {
          id
          award_id
          value
        }
        award_nominations(order_by: {award: {name: asc}}) {
          id
          award {
            id
            name
          }
          assets(limit: 1) {
            id
            file_name
          }
          membership {
            id
            registration {
              id
              first_name
              last_name
            }
          }
        }
      }
    }
    selection_progresses {
      id
      index
      seek
    }
  }
  awards(where: { max_nominations: { _neq: 0 } }, order_by: { name: asc }) {
    id
    name
    description
    max_nominations
  }
}`)

const UpsertSelectionProgress = gql(`mutation UpsertSelectionProgress($film_group_id: Int!, $index: Int!) {
  insert_selection_progress_one(
    object: { film_group_id: $film_group_id, index: $index, seek: 0 }
    on_conflict: {
      constraint: selection_progress_film_group_id_real_user_id_key
      update_columns: [index, seek]
    }
  ) {
    id index seek
  }
}`)

const UpsertSelectionMetric = gql(`mutation UpsertSelectionMetric(
  $award_id: numeric!
  $team_id: numeric!
  $value: Int!
) {
  insert_selection_metrics_one(
    object: { award_id: $award_id, team_id: $team_id, value: $value }
    on_conflict: {
      constraint: selection_metrics_real_user_id_team_id_award_id_key
      update_columns: value
    }
  ) {
    id
    team_id
    award_id
    value
    team {
      id
      selection_metrics_aggregate(where: {value: {_gt: 0}}) {
        aggregate {
          count
        }
      }
    }
  }
}`)
